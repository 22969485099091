import React from 'react';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { Accordion } from '@naf/accordion';
import { spacing } from '@naf/theme';
import { useLocation } from 'react-router';
import { AccordionBlockType } from '../../../../../types/AccordionBlockType';
import { useGTMDataLayer } from '../../../hooks/GTM/useGTMDataLayer';
import useSelector from '../../../redux/typedHooks';
import { NAFGTMEvent } from '../../../../../types/GTM/NAFGTMEvent';
import BlockContent from '../../block-content/BlockContent';
import { EventPayload } from '../../../../../types/GTM/EventPayload';

interface Props {
  block: AccordionBlockType;
}

export const AccordionBlock: React.FC<Props> = ({ block }) => {
  const { title, accordion, ingress } = block;

  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const baseUrl = useSelector((state) => state.application.baseUrl);

  const onOpen = (accordionTitle: string) => {
    if (dataLayer) {
      const eventPayload: EventPayload = {
        event: NAFGTMEvent.accordionOpen,
        listValue: `${accordionTitle}`,
        page: location.pathname,
        pageUrl: `${baseUrl}${location.pathname}`,
      };
      dataLayer.push(eventPayload);
    }
  };

  return (
    <AccordionBlockWrapper>
      <HeaderText variant={TextVariant.Header2}>{title}</HeaderText>
      {ingress && <BlockContent value={ingress} />}
      {accordion?.map((item) => (
        <Accordion label={item.title} key={item._key} id={item._key} onOpen={() => onOpen(item.title)}>
          <AccordionContent>
            <BlockContent value={item.text} />
          </AccordionContent>
        </Accordion>
      )) || null}
    </AccordionBlockWrapper>
  );
};

const HeaderText = styled(Text)`
  margin: 0;
`;

const AccordionContent = styled.div`
  p {
    margin: 0;
  }
  p:not(:last-child) {
    margin-bottom: ${spacing.space16};
  }
`;

// Allows this block to be selected by nesting styled-components
export const AccordionBlockWrapper = styled.div``;
